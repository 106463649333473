import { Modal, ModalBackButton } from '@/components/Modal'
import styles from '@pages/CompanyPassport/CompanyPassportModal.module.scss'
import { Button, Title } from '@/ui'
import { Typography } from '@pages/CompanyPassport/Typography/Typography'
import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import { useFetchData } from '@pages/CompanyPassport/hooks/useFetchData'
import { defaultFullInfo } from '@pages/CompanyPassport/dfault'
import { safetyService } from '@/shared/api/rest/safety/safetyService'
import { LoadingBlocker } from '@/components/Loader'
import { RiskStatus } from '@pages/CompanyPassport/RiskStatus'
import { useDispatch, useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '@/i18next'
import { handbookSelectors } from '@/entity/handbook'
import { companyActionsThunks } from '@features/company'

const Row = ({ title, value }) => {
	return (
		<>
			<div>
				<Typography variant={'subtitle'}>{title}</Typography>
			</div>
			<div>
				<Typography variant={'text'}>{value}</Typography>
			</div>
		</>
	)
}

const RiskTableColumn = ({ title, mainRisk, subRisks }) => {
	return (
		<div className={styles.risk_table_column}>
			<div className={styles.risk_table_header}>
				<Typography variant={'subtitle_bold'}>{title}</Typography>
				<RiskStatus riskType={mainRisk}></RiskStatus>
			</div>
			<hr />
			<div className={styles.risk_table_body}>
				{subRisks?.map((risk) => (
					<div className={styles.risk_table_cell} key={risk.title}>
						<Typography variant={'text_sm'}>{risk.title}</Typography>
						<RiskStatus riskType={risk.risk}></RiskStatus>
					</div>
				))}
			</div>
		</div>
	)
}

const PortreitColumn = ({ title, values, isUnionValue }) => {
	return (
		<div className={styles.company_portriet_table_cell}>
			<Typography variant={'subtitle'}>{title}</Typography>
			<div className={styles.company_portriet_table_sub_cell}>
				{values.map((item) => (
					<div className={styles.company_portriet_table_sub_cell_row} key={item.title}>
						<Typography variant={'text'}>
							{item.title} {isUnionValue ? item.value : ''}{' '}
						</Typography>
						{!isUnionValue && <Typography variant={'text'}>{item.value} </Typography>}
					</div>
				))}
			</div>
		</div>
	)
}

export const CompanyPassportModal = ({ onClose }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const { data, loading } = useFetchData({
		bin: activeCompany.bin,
		fetchFunction: safetyService.getFullInfo,
		defaultValue: defaultFullInfo,
	})

	useEffect(() => dispatch(companyActionsThunks.getOptions()), [dispatch])
	const activity = useSelector(handbookSelectors.okeds)

	const oked1 = useMemo(() => activity.find((item) => item.code === data?.codeOked1), [data, activity])
	const oked2 = useMemo(() => activity.find((item) => item.code === data?.codeOked2), [data, activity])

	const lang = getCurrentLanguage()
	const langPrefix = lang === 'rus' ? 'Ru' : 'Kz'

	const handleDownload = useCallback(async () => {
		const res = await safetyService.generatePdf(activeCompany.bin)
		const file = new Blob([res], { type: 'application/pdf' })
		const fileURL = URL.createObjectURL(file)
		window.open(fileURL)
	}, [data])

	const workRisksValues = useMemo(
		() => [
			{ title: t('ckp_moderate_severe_injuries'), risk: data?.riskTravma ?? '' },
			{ title: t('ckp_staff_growth_percentage'), risk: data?.riskStat ?? '' },
			{ title: t('ckp_staff_turnover_percentage'), risk: data?.riskTek ?? '' },
			{ title: t('ckp_dismissed_for_negative_reasons_percentage'), risk: data?.riskUvolOtr ?? '' },
			{ title: t('ckp_management_foreigners_vs_citizens_ratio'), risk: data?.riskCntRuk ?? '' },
			{ title: t('ckp_ERSON_inspections'), risk: data?.riskErsop ?? '' },
			{ title: t('ckp_foreigners_in_staff_percentage'), risk: data?.riskStatInostr ?? '' },
			{ title: t('ckp_city_forming_enterprise'), risk: data?.riskGradoob ?? '' },
		],
		[data, t]
	)

	const financialRisksValues = useMemo(
		() => [
			{ title: t('ckp_salary_difference_foreign_vs_citizens_management'), risk: data?.riskSmzRuk ?? '' },
			{ title: t('ckp_current_salary_below_30_percent'), risk: data?.riskSmz ?? '' },
			{ title: t('ckp_salary_disproportion_max_vs_min'), risk: data?.riskKratnostSmz ?? '' },
			{ title: t('ckp_salary_growth_rate'), risk: data?.riskTempRost ?? '' },
			{ title: t('ckp_overdue_credit_percentage'), risk: data?.riskKredit90 ?? '' },
		],
		[data, t]
	)

	const socialRisksValues = useMemo(
		() => [
			{ title: t('ckp_harmful_production_staff_percentage'), risk: data?.riskOppv ?? '' },
			{ title: t('ckp_large_family_staff_percentage'), risk: data?.riskMnogodet ?? '' },
			{ title: t('ckp_non_local_staff_percentage'), risk: data?.riskNotMestnyi ?? '' },
			{ title: t('ckp_young_staff_percentage'), risk: data?.riskMolodezh ?? '' },
			{ title: t('ckp_group_DE_percentage'), risk: data?.riskDe ?? '' },
		],
		[data, t]
	)

	const portrietAverageMonthSalaryValues = useMemo(
		() => [
			{ title: `${t('ckp_last_12_months')}-`, value: data?.smz12Mes ?? '0' },
			{ title: `${t('ckp_last_24_months')}-`, value: data?.smz24Mes ?? '0' },
		],
		[data, t]
	)

	const portrietMedianMonthSalaryValues = useMemo(
		() => [
			{ title: `${t('ckp_last_12_months')}-`, value: data?.med12Mes ?? '0' },
			{ title: `${t('ckp_last_24_months')}-`, value: data?.med24Mes ?? '0' },
		],
		[data, t]
	)

	const portreitWithWork = useMemo(
		() => [
			{ title: `${t('ckp_employed')}:`, value: data?.esutdPrinyato ?? '0' },
			{ title: `${t('ckp_dismissed')}:`, value: data?.esutdUvol ?? '0' },
			{ title: `${t('ckp_dismissed_negative_reasons')}:`, value: data?.esutdUvolBad ?? '0' },
		],
		[data, t]
	)

	const portreitChecks = useMemo(
		() => [
			{ title: `${t('ckp_prof_control')}:`, value: data?.checkTypeOtbScheduled ?? '0' },
			{ title: `${t('ckp_unscheduled_inspections')}:`, value: data?.checkTypeOtbUnScheduled ?? '0' },
			{ title: `${t('ckp_active_cases')}:`, value: data?.countActive ?? '0' },
			{ title: `${t('ckp_completed_cases')}:`, value: data?.countNotActive ?? '0' },
		],
		[data, t]
	)

	const portreitResultChecks = useMemo(
		() => [
			{ title: `${t('ckp_instructions_given')}:`, value: data?.prescription ?? '0' },
			{ title: `${t('ckp_administrative_cases_opened')}:`, value: data?.administrativeMatter ?? '0' },
		],
		[data, t]
	)

	const portreitDocuments = useMemo(
		() => [
			{ title: `${t('ckp_available_documents')}:`, value: data?.insuredEmployeeCount ?? '0' },
			{ title: `${t('ckp_not_available_documents')}:`, value: data?.notInsuredEmployeeCount ?? '0' },
			{ title: `${t('ckp_active_contracts')}:`, value: data?.activeContractCount ?? '0' },
			{ title: `${t('ckp_terminated_contracts')}:`, value: data?.quitedContractCount ?? '0' },
		],
		[data, t]
	)

	const portreitTraumatism = useMemo(
		() => [
			{ title: `${t('ckp_light_moderate_injuries')}:`, value: data?.countLight ?? '0' },
			{ title: `${t('ckp_severe_injuries')}:`, value: data?.countHeavy ?? '0' },
			{ title: `${t('ckp_fatalities')}:`, value: data?.countDied ?? '-' },
		],
		[data, t]
	)

	const violations = useMemo(
		() =>
			data?.violations?.map((violation) => ({
				title: violation[`violationType${langPrefix}`],
				description: violation[`description${langPrefix}`],
				count: violation.workersCount,
			})) ?? [],
		[data]
	)

	const workerProfileByStatus = useMemo(
		() => [
			{ title: `${t('ckp_hazardous_conditions')}:`, value: data?.oppv ?? '0' },
			{ title: `${t('ckp_local_workers')}:`, value: data?.mestnyi ?? '0' },
			{ title: `${t('ckp_non_local_workers')}:`, value: data?.notMestnyi ?? '0' },
			{ title: `${t('ckp_seasonal_workers')}:`, value: data?.esutdSeason ?? '0' },
			{ title: `${t('ckp_large_family_percentage')}:`, value: data?.cntMnogodet ?? '0' },
			{ title: `${t('ckp_ASP_recipients')}`, value: data?.asp ?? '0' },
			{ title: `${t('ckp_LSI_percentage')}`, value: data?.kolLsi ?? '0' },
			{ title: `${t('ckp_chronic_conditions_percentage')}`, value: data?.duchet ?? '0' },
		],
		[data, t]
	)

	if (loading) {
		return <LoadingBlocker />
	}

	return (
		<Modal windowClassName={styles.modal_container} onClose={onClose}>
			<ModalBackButton noMargin onClick={onClose} />
			<div className={styles.modal_title_container}>
				<Title color="black">{t('ckp_enterprise_passport')}</Title>
				<Button onClick={handleDownload}>{t('ckp_download_pdf')}</Button>
			</div>
			<div className={styles.modal_common_information}>
				<Typography variant={'subtitle_xl_blue'}>{t('ckp_general_info')}</Typography>
				<div className={styles.table}>
					<Row title={t('ckp_name')} value={data?.orgName}></Row>
					<Row title={t('ckp_BIN')} value={data?.bin}></Row>
					<Row title={t('ckp_manager')} value={data?.leaderName}></Row>
					<Row title={t('ckp_address')} value={data[`address${langPrefix}`]}></Row>
					<Row title={t('ckp_contact_info')} value={data?.contactInfo}></Row>
					<Row title={t('ckp_OKED')} value={oked1[lang === 'rus' ? 'rus' : 'kz']}></Row>
					<Row title={''} value={oked2[lang === 'rus' ? 'rus' : 'kz']}></Row>
					<Row title={t('ckp_Keitz_index')} value={data?.indexKeitca}></Row>
				</div>
			</div>
			<div className={styles.modal_indicator_information}>
				<Typography variant={'subtitle_xl_blue'}>{t('ckp_risk_map_indicators')}</Typography>
				<div className={styles.modal_risk_information}>
					<Typography variant={'subtitle_bold'}>{t('ckp_risk_level')}</Typography>
					<RiskStatus riskType={data?.finalTip}></RiskStatus>
				</div>
				<hr />
				<div className={styles.modal_risk_table}>
					<RiskTableColumn
						mainRisk={data?.riskFinalTipTrud}
						title={t('ckp_labor_risks')}
						subRisks={workRisksValues}
					></RiskTableColumn>
					<RiskTableColumn
						mainRisk={data?.riskFinalTipFinance}
						title={t('ckp_financial_risks')}
						subRisks={financialRisksValues}
					></RiskTableColumn>
					<RiskTableColumn
						mainRisk={data?.riskFinalTipSoc}
						title={t('ckp_social_risks')}
						subRisks={socialRisksValues}
					></RiskTableColumn>
				</div>
			</div>
			<div className={styles.company_portriet_container}>
				<Typography variant={'subtitle_xl_blue'}>{t('ckp_enterprise_portrait')}</Typography>
				<div className={styles.company_portriet_table}>
					<div className={styles.company_portriet_column}>
						<div className={styles.risk_table_header}>
							<Typography variant={'subtitle'}>{t('ckp_total_staff')}:</Typography>
							<Typography variant={'text'}>{data?.workedToday}</Typography>
						</div>
						<PortreitColumn
							title={t('ckp_average_salary_per_month')}
							values={portrietAverageMonthSalaryValues}
							isUnionValue
						></PortreitColumn>
						<PortreitColumn title={t('ckp_employment_dismissal')} values={portreitWithWork}></PortreitColumn>
						<PortreitColumn title={t('ckp_total_inspections')} values={portreitChecks}></PortreitColumn>
						<PortreitColumn title={t('ckp_measures_on_inspections')} values={portreitResultChecks}></PortreitColumn>
						<PortreitColumn title={t('ckp_OSRNS_contracts')} values={portreitDocuments}></PortreitColumn>
						<PortreitColumn title={t('ckp_injury_cases')} values={portreitTraumatism}></PortreitColumn>
					</div>
					<div className={styles.company_portriet_column}>
						<div className={styles.risk_table_header}>
							<Typography variant={'subtitle'}>{t('ckp_total_OPV_staff_last_3_months')}:</Typography>
							<Typography variant={'text'}>{data?.kol3Mes}</Typography>
						</div>
						<PortreitColumn
							title={t('ckp_median_salary_per_month')}
							values={portrietMedianMonthSalaryValues}
							isUnionValue
						></PortreitColumn>
					</div>
				</div>
			</div>
			<div className={styles.violation_container}>
				<Typography variant={'subtitle_xl_blue'}>{t('ckp_violations')}</Typography>
				<div className={styles.violation_table}>
					<Typography variant={'subtitle_bold'}>{t('ckp_violation_type')}</Typography>
					<Typography variant={'subtitle_bold'}>{t('ckp_description')}</Typography>
					<Typography variant={'subtitle_bold'}>{t('ckp_workers')}</Typography>
					<div className={styles.violation_table_delimiter}>
						<hr />
					</div>
					{violations.map((violation) => (
						<Fragment key={violation.title}>
							<Typography>{violation.title}</Typography>
							<Typography>{violation.description}</Typography>
							<Typography>{violation.count}</Typography>
							<div className={styles.violation_table_delimiter}>
								<hr />
							</div>
						</Fragment>
					))}
				</div>
			</div>
			<div className={styles.worker_profile_container}>
				<Typography variant={'subtitle_xl_blue'}>{t('ckp_workers_portrait')}</Typography>
				<div className={styles.worker_profile_table}>
					<PortreitColumn title={t('ckp_status_based')} values={workerProfileByStatus}></PortreitColumn>
					<div className={styles.worker_profile_prosperity_container}>
						<Typography variant={'subtitle'}>{t('ckp_welfare_based')}</Typography>
						<div className={styles.worker_profile_prosperity_table}>
							<Typography variant={'text'}>{t('ckp_income_per_family_member')}</Typography>
							<Typography variant={'text'}>
								{t('ckp_below_poverty_line')} - {data?.employeesIncomeBelowPoverty ?? '0'}
							</Typography>
							<Typography variant={'text'}></Typography>
							<Typography variant={'text'}>
								{t('ckp_below_minimum_wage')} - {data?.employeesIncomeBelowMonthlyMinimum ?? '0'}
							</Typography>
							<Typography variant={'text'}>{t('ckp_indebtedness')}</Typography>
							<Typography variant={'text'}>
								{t('ckp_has_loans')} - {data?.workersWithLoan ?? '0'}
							</Typography>
							<Typography variant={'text'}></Typography>
							<Typography variant={'text'}>
								{t('ckp_loan_overdue_90_days')} - {data?.workersWithLoansOverdue90Days ?? '0'}
							</Typography>
							<Typography variant={'text'}>{t('ckp_category_A')}</Typography>
							<Typography variant={'text'}>{data?.percentageCategoryAcks ?? '0'}%</Typography>
							<Typography variant={'text'}>{t('ckp_category_B')}</Typography>
							<Typography variant={'text'}>{data?.percentageCategoryBcks ?? '0'}%</Typography>
							<Typography variant={'text'}>{t('ckp_category_C')}</Typography>
							<Typography variant={'text'}>{data?.percentageCategoryCcks ?? '0'}%</Typography>
							<Typography variant={'text'}>{t('ckp_category_D')}</Typography>
							<Typography variant={'text'}>{data?.percentageCategoryDcks ?? '0'}%</Typography>
							<Typography variant={'text'}>{t('ckp_category_E')}</Typography>
							<Typography variant={'text'}>{data?.percentageCategoryEcks ?? '0'}%</Typography>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}
