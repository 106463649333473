import { useEffect, useState } from 'react'

export const useFetchData = ({ bin, fetchFunction, defaultValue, isArray }) => {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState(defaultValue)
	const [isEmpty, setIsEmpty] = useState(false)
	const [isEmptyObjects, setIsEmptyObjects] = useState(false)

	useEffect(async () => {
		if (!bin) return
		try {
			setLoading(true)

			const data = await fetchFunction(bin)

			if (isArray) {
				// Если массив пустой, установить IsEmpty в true
				if (data.length === 0) {
					console.log(data.length, 'data.length')
					setIsEmptyObjects(true)
					return
				}

				// Если хотя бы у одного объекта есть значение в поле 'bin', не заходить в проверку
				if (!data.some((item) => item.bin)) {
					setIsEmptyObjects(true)
					return
				}
			} else {
				if (!data.bin) {
					setIsEmpty(true)
					return
				}
			}

			setData(data)
		} catch (error) {
			setIsEmpty(true)
			setIsEmptyObjects(true)
			setLoading(false)
		} finally {
			setLoading(false)
		}
	}, [bin, fetchFunction, defaultValue, setData])

	return { loading, data, isEmpty, isEmptyObjects }
}
